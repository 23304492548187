import { Constants } from '@portal/config/env';
import { getCookie } from '@portal/services';
import { ProductType, ProfileType, arrayAdminProfiles } from '~/enum';

export const getJourneyUrl = (id: string, profileType: ProfileType) => {
  switch (profileType) {
    case ProfileType.ADMIN:
      return `${Constants.journeyUrl}/journeyMap/${id}`;

    case ProfileType.STUDENT:
      return `${Constants.journeyUrl}/studentJourneyMap/${id}`;

    case ProfileType.COORDINATOR:
    case ProfileType.ADVISER:
    case ProfileType.TEACHER:
      return `${Constants.journeyUrl}/schoolJourneyMap/${id}`;

    default:
      return '';
  }
};

export const getLMSUrl = (lms: models.Products, profileType: ProfileType) => {
  const token = getCookie('portal-token');

  const isLmsStudent = (lms.product || lms.productType) === ProductType.LMS_STUDENT;

  if (arrayAdminProfiles.includes(profileType) && isLmsStudent) {
    return `${Constants.dashboardUrl}/redirect?token=${token}&currentPath=lms/electives/${lms.id}`;
  }

  if (profileType === ProfileType.TEACHER && isLmsStudent) {
    return `${Constants.lmsUrl}/redirect?token=${token}&productId=${lms?.id}&path=groups`;
  }

  return `${Constants.lmsUrl}/redirect?token=${token}&productId=${lms?.id}`;
};
