import React, { ComponentProps, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { Constants } from '@portal/config/env';

interface IRenderPdf extends ComponentProps<'div'> {
  fileUrl: string;
  showModalFullScreen?: boolean;
  setShowModalFullScreen?: Dispatch<SetStateAction<boolean>>;
}

const RenderPdf = ({ fileUrl, setShowModalFullScreen, showModalFullScreen, ...rest }: IRenderPdf) => {
  const viewer = useRef<HTMLDivElement>(null);

  const [newInstance, setNewInstance] = useState<WebViewerInstance>();

  const disabledElements = [
    'toolbarGroup-Edit',
    'moreButton',
    'thumbDelete',
    'deletePage',
    'extractPage',
    'insertPage',
    'replacePage',
    'pageManipulationHeader',
    'menuOverlay',
    'menuButton',
    'dropdown-item-toolbarGroup-Annotate',
    'dropdown-item-toolbarGroup-Shapes',
    'dropdown-item-toolbarGroup-Insert',
    'dropdown-item-toolbarGroup-FillAndSign',
    'dropdown-item-toolbarGroup-Forms',
    'ribbonsDropdown',
    'toolbarGroup-Annotate',
    'toolbarGroup-Shapes',
    'toolbarGroup-Insert',
    'toolbarGroup-FillAndSign',
    'toolbarGroup-Forms',
    'toolbarGroup-View',
    'selectToolButton',
    'toolsHeader',
    'stickyToolButton',
    'highlightToolButton',
    'markReplaceTextToolButton',
    'markInsertTextToolButton',
    'freeTextToolButton',
    'freeHandHighlightToolButton',
    'freeHandToolButton',
    'toggleNotesButton',
  ];

  useEffect(() => {
    if (viewer?.current && fileUrl && !newInstance) {
      WebViewer(
        {
          path: process.env.PUBLIC_URL + '/webviewer/lib',
          licenseKey:
            'Studos Software Ltda (studos.com.br):OEM:Studos::B+:AMS(20240809):E1DCE12C07FAFAF3FB313BC9B243182F4E6F3FD7763A639BB57BB43C404EB604F431F5C7',
          initialDoc: fileUrl,
          disabledElements,
        },
        viewer?.current
      )
        .then((instance) => {
          if (instance) {
            const {
              UI: { setLanguage, setLayoutMode, Languages, LayoutMode, setFitMode, FitMode },
            } = instance;

            setLanguage(Languages.PT_BR);
            setLayoutMode(LayoutMode.Continuous);
            setFitMode(FitMode.FitWidth);

            setNewInstance(instance);
          }
        })
        .catch((err) => console.log('Falha ao iniciar o WebViewer', err));
    }
  }, [fileUrl, newInstance]);

  return <div ref={viewer} style={{ height: '100%', width: '100%' }} key={fileUrl} {...rest} />;
};

export default RenderPdf;
