import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { BrandActions, UserActions } from '@portal/actions';
import { useReduxState } from '@portal/hooks/useReduxState';

import { Constants } from '@portal/config/env';
import { setCookie } from '@portal/services/cookie';
import * as StorageService from '~/services/storage';
import { arrayAdminProfiles, isEducator } from '@portal/enum/profileType';
import { setAuthorizationHeader } from '@portal/repositories/instance';
import { setThemeBrand, setThemeProduct } from '@portal/utils/setTheme';
import { getMe } from '@portal/actions/auth';
import * as CookieService from '~/services/cookie';

const RedirectLMSPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { me } = useReduxState().auth;
  const { brandConfig, brandProductConfig } = useReduxState().brand;
  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  const courseId = params.get('courseId');
  const productId = params.get('productId');
  const newPath = params.get('path');

  const tokenCookie = CookieService.getCookie('portal-token');

  useEffect(() => {
    if (!brandConfig) {
      dispatch(
        BrandActions.getProductsConfig((config) => {
          setThemeBrand(config);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (token) {
      setAuthorizationHeader(params.get('token') as string);
      setCookie('portal-token', token, Constants.brandCookieDomain as string);
      setCookie('user-integration', 'true', Constants.brandCookieDomain as string);

      dispatch(getMe());
    }
  }, [token]);

  useEffect(() => {
    if (me?.profileType && token && arrayAdminProfiles.includes(me?.profileType)) {
      setCookie('admin-cookie/token', token, Constants.brandCookieDomain as string);
    }
  }, [me?.profileType, token]);

  useEffect(() => {
    if (productId && !brandProductConfig) {
      dispatch(
        BrandActions.getBrandProductsConfig(productId, (config) => {
          setThemeProduct(config);
        })
      );
    }
  }, [productId]);

  useEffect(() => {
    if (courseId) {
      StorageService.setItem('courseId-redirect', courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (me?.profileType && courseId && productId && !me?.idSchool && tokenCookie) {
      if (isEducator(me?.profileType)) {
        dispatch(
          UserActions.changeSchool(
            me?.id || '',
            {
              idSchool: me?.schools?.[0]?.idSchool || '',
            },
            () => window.location.replace(`/home/${productId}/groups?courseId=${courseId}`)
          )
        );
      } else {
        window.location.replace(`/content/${courseId}`);

        return;
      }
    }

    if (productId && courseId && me?.idSchool && tokenCookie) {
      window.location.replace(`/home/${productId}/groups?courseId=${courseId}`);
    }

    if (productId && !courseId && tokenCookie) {
      window.location.replace(`/home/${productId}${newPath ? `/${newPath}` : ''}`);
    }
  }, [me, courseId, productId, tokenCookie, newPath]);

  return <div></div>;
};

export default RedirectLMSPage;
