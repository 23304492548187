import Axios, { AxiosInstance, AxiosResponse } from 'axios';

import { Constants } from '@portal/config/env';
import { getCookie } from '@portal/services/cookie';

// DEFAULT
const headersDefault = {
  'Content-Type': 'application/json',
  mode: 'cors',
  'arco-brand-domain': Constants.brandDomain,
  Authorization: `Bearer ${getCookie('portal-token')}`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const interceptError = async (err: any) => {
  if (err.response && err.response.status === 401) {
    window.location.replace(`${Constants.portalUrl}/errors/unauthorized`);

    return;
  }

  if (err.response) {
    return Promise.reject(err);
  }
};

export const setAuthorizationHeader = (token: string): void => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  newAxiosInstanceV1.defaults.headers.Authorization = `Bearer ${token}`;
  newAxiosInstanceV2.defaults.headers.Authorization = `Bearer ${token}`;
  journeyAxiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const setBrandDomainHeader = (brandDomain: string): void => {
  axiosInstance.defaults.headers['arco-brand-domain'] = brandDomain;
  newAxiosInstanceV1.defaults.headers['arco-brand-domain'] = brandDomain;
  newAxiosInstanceV2.defaults.headers['arco-brand-domain'] = brandDomain;
  journeyAxiosInstance.defaults.headers['arco-brand-domain'] = brandDomain;
};

// CREATE INSTANCES
const axiosInstance = Axios.create({
  baseURL: Constants.backOld,
  headers: headersDefault,
});

const newAxiosInstanceV1 = Axios.create({
  baseURL: `${Constants.backNew}/v1`,
  headers: headersDefault,
});

const newAxiosInstanceV2 = Axios.create({
  baseURL: `${Constants.backNew}/v2`,
  headers: headersDefault,
});

const journeyAxiosInstance = Axios.create({
  baseURL: `${Constants.backJourney}/v1`,
  headers: headersDefault,
});

const bffContentLibraryAxiosInstance = Axios.create({
  baseURL: `${Constants.backBffContentLib}/v1`,
  headers: headersDefault,
});

// INTERCEPTORS
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    await interceptError(err);
  }
);

newAxiosInstanceV1.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    await interceptError(err);
  }
);

newAxiosInstanceV2.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    await interceptError(err);
  }
);

journeyAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    await interceptError(err);
  }
);

bffContentLibraryAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    await interceptError(err);
  }
);

// GET INSTANCES
export const getInstance = (options?: {
  api?: 'old' | 'new' | 'journey' | 'bffContentLibrary';
  version?: 'v1' | 'v2';
}): AxiosInstance => {
  if (options?.api === 'new') {
    switch (options?.version) {
      case 'v1':
        return newAxiosInstanceV1;
      case 'v2':
        return newAxiosInstanceV2;
      default:
        return newAxiosInstanceV1;
    }
  }

  if (options?.api === 'journey') {
    return journeyAxiosInstance;
  }

  if (options?.api === 'bffContentLibrary') {
    return bffContentLibraryAxiosInstance;
  }

  return axiosInstance;
};
